<template>
  <div>
    <div class="container-fluid mt--9">
      <div class="row">
        <div class="col-xl-12 order-xl-1">
          <card shadow type="secondary">
            <form>
              <hr class="my-4" />
              Address
              <h6 class="heading-small text-muted mb-4">User</h6>
              <div class="pl-lg-4">
                <div class="row">
                  <div class="col-md-12">
                    <base-input
                      alternative=""
                      label="Username"
                      placeholder="Username"
                      input-classes="form-control-alternative"
                      v-model="model.username"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6">
                    <base-input
                      alternative=""
                      label="Password"
                      placeholder="Password"
                      input-classes="form-control-alternative"
                      v-model="model.password"
                    />
                  </div>
                  <div class="col-lg-6">
                    <base-input
                      alternative=""
                      label="Confirm Password"
                      placeholder="Confirm Password"
                      input-classes="form-control-alternative"
                      v-model="model.confirmpassword"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <base-input
                      alternative=""
                      label="Email"
                      placeholder="Email"
                      type="email"
                      input-classes="form-control-alternative"
                      v-model="model.email"
                    />
                  </div>
                </div>
                <div class="row1">
                  <div class="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      id="customCheck1"
                    />
                    <label class="custom-control-label" for="customCheck1"
                      >Active?</label
                    >
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-5"></div>
                <div class="col-md-4">
                  <button
                    type="button"
                    class="btn btn-default"
                    v-on:click="create()"
                  >
                    Create
                  </button>
                </div>
              </div>
            </form>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "add_member",
  data() {
    return {
      model: {
        username: "",
        email: "",
        confirmpassword: "",
        password: "",
      },
    };
  },

  methods: {
    // Pushes posts to the server when called.
    create() {
      let baseDomain = "https://shulsoftware.adass.com.au/api/index.php";
      axios
        .post(baseDomain, {
          body: this.model,
        })

        .catch((e) => {
          this.errors.push(e);
        });
    },
  },
};
</script>
<style></style>
